<app-internal-header></app-internal-header>
<div class="waiting-queue-container">
    <img src="./assets/images/waiting-list.png" alt="waiting-list" width="185px" class="queue-icon">
    <h2 class="queue-title">You're in the queue</h2>
    <p class="queue-message">
        You're now in the queue for an incredible opportunity to connect with top hospitals across Saudi Arabia. 🚀 
    </p>
    <p class="queue-message">
        Stay tuned—exciting opportunities are coming your way soon!
    </p>
</div>
<app-internal-footer></app-internal-footer>